<template>
    <div id="tenmo">
        <TEnmo/>
    </div>
</template>

<script>
import TEnmo from '@/components/TEnmo.vue';

export default {
    components: {
    TEnmo
}
}
</script>

<style scoped>

#tenmo {
    max-width:700px;
}

</style>
