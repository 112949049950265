<template>
    <div id="medScheduling">
        <med-scheduling />
    </div>
</template>

<script>
import medScheduling from '../components/MedScheduling.vue'

export default {
    components: {
        medScheduling
    }
}
</script>

<style scoped>

#vendingMachine {
    max-width:700px;
}

</style>
